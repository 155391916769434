<template> 
    <div class="tinymce-box">
		<Editor v-model="contentValue" :init="init"  ref="editor"/>
	</div>
</template>
<script setup>
import { defineProps, onMounted, reactive,watch,defineEmits,ref,defineExpose } from "vue";
import axios from "axios"
import Dialog from "@/utils/ui/dialog"
import Editor from "@tinymce/tinymce-vue";
//引入node_modules里的tinymce相关文件文件
import tinymce from "tinymce/tinymce"; //tinymce默认hidden，不引入则不显示编辑器
import "tinymce/themes/silver"; //编辑器主题，不引入则报错
import "tinymce/icons/default"; //引入编辑器图标icon，不引入则不显示对应图标

// 引入编辑器插件（基本免费插件都在这儿了）
import "tinymce/plugins/advlist"; //高级列表
import "tinymce/plugins/anchor"; //锚点
import "tinymce/plugins/autolink"; //自动链接
import "tinymce/plugins/autoresize"; //编辑器高度自适应,注：plugins里引入此插件时，Init里设置的height将失效
import "tinymce/plugins/autosave"; //自动存稿
import "tinymce/plugins/charmap"; //特殊字符
import "tinymce/plugins/code"; //编辑源码
import "tinymce/plugins/codesample"; //代码示例
import "tinymce/plugins/directionality"; //文字方向
import "tinymce/plugins/emoticons"; //表情
import "tinymce/plugins/fullpage"; //文档属性
import "tinymce/plugins/fullscreen"; //全屏
import "tinymce/plugins/help"; //帮助
import "tinymce/plugins/hr"; //水平分割线
import "tinymce/plugins/image"; //插入编辑图片
import "tinymce/plugins/importcss"; //引入css
import "tinymce/plugins/insertdatetime"; //插入日期时间
import "tinymce/plugins/link"; //超链接
import "tinymce/plugins/lists"; //列表插件
import "tinymce/plugins/media"; //插入编辑媒体
import "tinymce/plugins/nonbreaking"; //插入不间断空格
import "tinymce/plugins/pagebreak"; //插入分页符
import "tinymce/plugins/paste"; //粘贴插件
import "tinymce/plugins/preview"; //预览
import "tinymce/plugins/print"; //打印
import "tinymce/plugins/quickbars"; //快速工具栏
import "tinymce/plugins/save"; //保存
import "tinymce/plugins/searchreplace"; //查找替换
// import 'tinymce/plugins/spellchecker'  //拼写检查，暂未加入汉化，不建议使用
import "tinymce/plugins/tabfocus"; //切入切出，按tab键切出编辑器，切入页面其他输入框中
import "tinymce/plugins/table"; //表格
import "tinymce/plugins/template"; //内容模板
import "tinymce/plugins/textcolor"; //文字颜色
import "tinymce/plugins/textpattern"; //快速排版
import "tinymce/plugins/toc"; //目录生成器
import "tinymce/plugins/visualblocks"; //显示元素范围
import "tinymce/plugins/visualchars"; //显示不可见字符
import "tinymce/plugins/wordcount"; //字数统计
import "tinymce/plugins/ax_wordlimit/plugin.js"

import {getImgUrl} from "@/utils/imageRelated"
const props = defineProps({
  types:{
    type:String,
    default:""
  },
  placeholder:{
    type:String,
    default:""
  },
  value: {
    type: String,
    default: "",
  },
  maxLength:{
    type:Number,
    default:1300
  },
  plugins: {
    type: [String, Array],
    default:
      "print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern autosave code ax_wordlimit",
  },
  toolbar: {
    type: [String, Array],
    default:
      "fullscreen undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | \
                styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
                table image media charmap hr pagebreak insertdatetime print preview | code selectall searchreplace visualblocks | indent2em lineheight formatpainter axupimgs",
  },
});
const emits=defineEmits(["onclick","inputContent"])
const init = reactive({
  language_url: "./tinymce/zh_CN.js",
  language: "zh_CN",
  skin_url: "./tinymce/skins/ui/oxide",
  plugins: props.plugins,
  toolbar: props.toolbar,
  fontsize_formats:"12px 14px 16px 18px 20px 22px 24px 28px 32px 36px 48px 56px 72px",
  font_formats:"微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
  lineheight_formats: "0.5 0.8 1 1.2 1.5 1.75 2 2.5 3 4 5",
  height: 400,
  placeholder: props.placeholder,
  branding:true,
  resize: true, //编辑器宽高是否可变
  statusbar: true, //元素路径与字数统计
  elementpath: false, //是否显示元素路径
  content_style: "img{width:100%;max-height:400px;object-fit:cover}",
  paste_data_images: true,
  wordlimit: {
    max: props.maxLength, // 最多可以输入多少字
    spaces: !1, // 是否含空格
    isInput: !1, // 是否在超出后还可以输入
    // 自定义的提示方法, 默认用编辑器自带
    toast: function(message) {
        alert(message)
    }
},
  images_upload_handler: (blobInfo, success, failure) => {
    if (blobInfo.blob().size / 1024 / 1024 > 4) {
      failure("上传失败，图片大小请控制在 4M 以内");
    } else {
      let params = new FormData();
      params.append("file", blobInfo.blob());
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .post(`https://v1.hitzone.cn/api/article/UploadFile`, params, config)
        .then((res) => {
          if (!!res.data) {
            success(getImgUrl(res.data)); //上传成功，在成功函数里填入图片路径
          } else {
            failure("上传失败");
          }
        })
        .catch(() => {
          failure("上传出错，服务器开小差了呢");
        });
    }
  },
  init_instance_callback:(edit)=>{
    edit.on('wordlimit', function(e) {
    var beyond = 0;
    if(e.wordCount > e.maxCount) {
        beyond = e.wordCount - e.maxCount;
    }
      keepLastIndex(edit.contentWindow.document.getElementById('tinymce'), edit.contentWindow)
});
  }
});
const contentValue=ref(props.value)
watch(contentValue,(val)=>{
    emits("inputContent",val,props.types)
})
onMounted(()=>{
    tinymce.init({})
})
const editor=ref(null)
const clearContent=()=>contentValue.value=""
const keepLastIndex= (obj, window)=> {
      if (window.getSelection) { //ie11 10 9 ff safari
        obj.focus(); //解决ff不获取焦点无法定位问题
        var range = window.getSelection(); //创建range
        range.selectAllChildren(obj); //range 选择obj下所有子内容
        range.collapseToEnd(); //光标移至最后
      } else if (document.selection) { //ie10 9 8 7 6 5
        var range = document.selection.createRange(); //创建选择对象
        range.moveToElementText(obj); //range定位到obj
        range.collapse(false); //光标移至最后
        range.select();
      }
    }
defineExpose({
  clearContent
})
</script>
<style lang="less" scoped></style>
