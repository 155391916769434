import {toRaw} from "vue"
import axios from "axios"
const uploadImg = (fileList,defaultImg="") => {
    const formData = new FormData();
    fileList.forEach((e) => {
      formData.append("file",e);
    });
    return new Promise((resolve, reject) => {
      axios
        .post("https://v1.hitzone.cn/api/article/UploadFile", formData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          resolve(defaultImg);
        });
    });
  };
  export default uploadImg