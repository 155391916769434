import {ref,watchEffect} from "vue"
import Dialog from "@/utils/ui/dialog"
const maxSize=5
export default function useUploadFile(preview,fn=null){
    const imgs=ref([])
    const fileList=ref([])
    const uploadRef=ref(null)
    const hideUpload=ref(false)
    const beforeUpload=(file)=>{
        if(file.size/1024/1024>maxSize){
            Dialog.message("error","文件大小不可超过5M")
            return false
        }
        img2Base64(file)
    }
    const fileChange=(file)=>{
        hideUpload.value=true
        if(fn){
            fn();
        }
        if(file.size/1024/1024>maxSize){
            uploadRef.value.clearFiles()
            Dialog.message("error","文件大小不可超过5M")
            return false
        }
        img2Base64(file)
    }
    const img2Base64=(file)=>{
        const reader=new FileReader()
        reader.readAsDataURL(file.raw)
        reader.onload=()=>{
            imgs.value.push(file.raw)
            fileList.value=[file]
        }
    }
    const handlePicPreview=(file)=>{
        const reader=new FileReader()
        reader.readAsDataURL(file.raw)
        reader.onload=()=>{
            preview.show=true
            preview.url=reader.result
        }
    }
    const handleRemove=(file)=>{
        uploadRef.value.clearFiles()
        imgs.value = imgs.value.filter(e=>e.uid!==file.uid)
        setTimeout(() => {
        hideUpload.value=false
        }, 1000);
    }
    return {
        imgs,
        uploadRef,
        beforeUpload,
        fileChange,
        handlePicPreview,
        handleRemove,
        hideUpload,
        fileList
    }
}